import { apolloClient as client } from '~/plugins/apollo';
import { AI_GENERATE_SCRIPT_MUTATION, AI_SAVE_SCRIPT_MUTATION } from '~/graphql/mutations';

export const state = {
  generatingScripts: false,
  title: undefined,
  background: undefined,
  canvas: null,
  template: null,
  scripts: [],
  isGenerating: false,
};

export const getters = {
  title: state => state.title,
  scripts: state => state.scripts,
  canvas: state => state.canvas,
  template: state => state.template,
  isGenerating: state => state.isGenerating,
};

export const mutations = {
  SET_SCRIPT(state, data) {
    state.scripts = [...data, {}];
  },
  UPDATE_TITLE(state, title) {
    state.title = title;
  },
  UPDATE_BG(state, background) {
    state.background = background;
  },
  UPDATE_CANVAS(state, canvas) {
    state.canvas = canvas;
  },
  UPDATE_TEMPLATE(state, template) {
    state.template = template;
  },
  SET_IS_GENERATING(state, isGenerating) {
    state.isGenerating = isGenerating;
  },
};

export const actions = {
  async generateScripts({ commit }, { message, workspace_id, id, count }) {
    async function delay(time) {
      return new Promise(resolve => setTimeout(resolve, time));
    }

    commit('SET_IS_GENERATING', true);
    let currentResponse = null;
    let attempts = 0;

    async function generate() {
      const { data } = await client.mutate({
        mutation: AI_GENERATE_SCRIPT_MUTATION,
        variables: {
          id: id || currentResponse?.id,
          message: message,
          workspace_id: workspace_id,
          count: count || 10,
        },
      });
      commit('SET_SCRIPT', data?.generateVideoScript?.scripts || []);
      currentResponse = data?.generateVideoScript;

      if (attempts > 100) {
        commit('SET_IS_GENERATING', false);
        return;
      }

      if (currentResponse?.status === 'processing') {
        await delay(3000);
        await generate();
        attempts++;
      } else {
        commit('SET_IS_GENERATING', false);
        return;
      }
    }

    generate().catch(e => {
      commit('SET_IS_GENERATING', false);
      console.error(e);
    });
  },
  async proceedToEditor({ state }) {
    state.generatingScripts = true;
    const { data } = await client.mutate({
      mutation: AI_SAVE_SCRIPT_MUTATION,
      variables: {
        title: state.title,
        background: state.background,
        canvas: JSON.stringify(state.canvas),
        template_id: state.template,
        thumbnail: state.thumbnail,
        scripts: state.scripts
          .map(e => {
            if (!e.voiceover) {
              e.voiceover = e.displaytext;
            }
            return e;
          })
          .filter(e => e.displaytext),
      },
    });
    state.generatingScripts = false;
    return data?.saveVideoWizardStep?.id;
  },
};
