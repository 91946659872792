<template>
  <div class="user-menu" :class="{ 'user-menu--active': showUserMenu }">
    <div id="dropDown" class="user-menu__button" @click="showUserMenu = !showUserMenu">
      <div class="user-menu-info btn soft-outline" style="color: #1b4332">
        <b-avatar class="avatar" size="2rem" :src="user.photo"></b-avatar>
        <div>
          <span class="name">{{ user.firstName }}</span>
        </div>
        <svg-icon name="chevron-down" class="ml-3 mt-1" size="sm" />
      </div>
    </div>

    <div class="user-menu__menu-box">
      <div class="user-menu__menu-box-title mb-3">
        <div class="d-flex gap-3">
          <div>
            <b-avatar class="avatar" size="4rem" :src="user.photo"></b-avatar>
          </div>
          <div class="pl-4">
            <div class="mb-2 text-left">
              <span class="name ">{{ user.firstName }} {{ user.lastName }}</span>
            </div>
            <div class="mt-1">
              <router-link
                class="title-action mt-3"
                title="Account Settings"
                :to="{ name: 'settings.profile' }"
                exact
                @click.native="hide()"
              >
                <svg-icon name="settings" class="mr-1" size="sm" />
                Account Setting
              </router-link>
            </div>
          </div>
        </div>
        <!---->
      </div>
      <b-row class="bg-dark-green">
        <!-- <b-col class="user-menu__menu-box-left">
          <ul class="user-menu__menu">
            <li data-name="Lead" class="user-menu__item">
              <router-link class="item-link" title="Leads" :to="{ name: 'settings.leads' }" exact>
                <div class="item-icon">
                  <svg-icon name="leads" size="md" />
                </div>
                Leads
              </router-link>
            </li>
            <li data-name="crm" class="workspace-menu__item">
              <router-link class="item-link disabled" title="CRM" to="#no-link" exact :disabled="true">
                <div class="item-icon">
                  <svg-icon name="crm" class="" size="md" />
                </div>
                CRM
              </router-link>
            </li>
          </ul>
        </b-col> -->
        <b-col class="workspace-menu__menu-box-center p-0">
          <ul class=" workspace-menu__menu p-3">
            <li class="workspace-menu__item">
              <router-link
                class="item-link"
                title="Leads"
                :to="{ name: 'settings.leads' }"
                exact
                @click.native="hide()"
              >
                <div class="item-icon">
                  <svg-icon name="leads" size="md" />
                </div>
                Leads
              </router-link>
            </li>
            <li class="workspace-menu__item">
              <router-link class="item-link" title="Reseller" :to="{ name: 'reseller' }" exact @click.native="hide()">
                <div class="item-icon">
                  <svg-icon name="subtitles" class="" size="md" />
                </div>
                Reseller
              </router-link>
            </li>
            <li data-name="bonus" class="workspace-menu__item">
              <!-- TODO: update link -->
              <b-link
                href="https://docs.google.com/document/d/12ZKHXPbxZRR5114YyhifShavNMjYXFtd440pZ9Wgdpc/edit?usp=sharing"
                target="_blank"
                style="font-size: 14px;"
                class="item-link"
                @click.native="hide()"
              >
                <div class="item-icon">
                  <svg-icon name="gift" size="md" />
                </div>
                Bonuses &amp; Resources
              </b-link>
            </li>
            <li class="workspace-menu__item">
              <router-link
                class="item-link"
                title="AgencyVideos"
                style="font-size: 14px;"
                :to="{ name: 'demo.video' }"
                exact
                @click.native="hide()"
              >
                <div class="item-icon">
                  <svg-icon name="video-alt" size="md" />
                </div>
                Agency Videos
              </router-link>
            </li>
            <li data-name="help" class="workspace-menu__item">
              <!-- TODO: update link -->
              <b-link
                href="https://support.socialagency360.com"
                target="_blank"
                class="item-link"
                style="font-size: 14px;"
                @click.native="hide()"
              >
                <div class="item-icon">
                  <svg-icon name="aid" size="md" />
                </div>
                Help Center
              </b-link>
            </li>
            <li data-name="help" class="workspace-menu__item">
              <!-- TODO: update link -->
              <b-link
                href="https://socialagency360.com/video-tutorial/"
                target="_blank"
                class="item-link d-flex align-items-center"
                style="font-size: 14px;"
                @click.native="hide()"
              >
                <div class="item-icon">
                  <svg-icon name="stars" size="md" />
                </div>
                Webinars
              </b-link>
            </li>
            <li data-name="help" class="workspace-menu__item">
              <!-- TODO: update link -->
              <b-link
                href="https://app.useagencyai.com/"
                target="_blank"
                class="item-link d-flex align-items-center justify-content-between"
                title="AI Tools"
                style="font-size: 14px;"
                @click.native="hide()"
              >
                <div>
                  <svg-icon name="ai-icon" size="md" class="mr-2" />
                  <span> AI Tools</span>
                </div>
                <div class="barge">Premium Bonus</div>
              </b-link>
            </li>
            <li data-name="help" class="workspace-menu__item">
              <!-- TODO: update link -->
              <!-- <b-button
                class="btn-sm btn-left  relative text-white"
                style="background-color: #634cd4;"
                variant="success"
              >
                <a href="https://app.useagencyai.com/" target="_blank" class="item-link text-white" title="AI Tools">
                  AI Tools
                </a>
              </b-button> -->
            </li>
          </ul>
        </b-col>
      </b-row>
      <div class="mt-3">
        <b-button
          variant="default"
          class="title-logout bg-dark-green py-2 f-12 no"
          @click.prevent="logout"
          title="logout"
        >
          <b-spinner v-if="authBusy" class="mr-1" small />
          <svg-icon name="power" class="" />
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    user: {
      type: Object,
      required: true,
    },
    authBusy: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      showUserMenu: false,
    };
  },

  created() {
    window.addEventListener('click', e => {
      if (!this.$el.contains(e.target)) {
        this.showUserMenu = false;
      }
    });
  },

  methods: {
    async logout() {
      // Log out the user.
      await this.$store.dispatch('auth/logout');
      // Redirect to login.
      this.$router.push({ name: 'signin' });
    },

    hide() {
      this.showUserMenu = false;
    },
  },
};
</script>

<style lang="scss">
@import '~@/scss/variables';
.bg-dark-green.no {
  background-color: #112b20;
}
.bg-dark-green {
  background-color: #143125;
  border-radius: 5px;
}
.barge {
  font-size: 10px;
  background: #ff0000;
  color: yellow;
  border-radius: 10px;
  display: inline-block;
  padding: 3px 10px;
  // position: absolute;
  // left: -5px;
  // top: -5px;
  // transform: rotate(-25deg);
}
.user-menu {
  z-index: 9999;
  display: inline-block;
  position: relative;
  &__button {
    background: transparent;
    display: inline-block;
  }
  &-info {
    display: flex;
    align-items: center;
    text-align: left;
    padding: 5px;
    background: rgba(0, 0, 0, 0.05);
    .avatar {
      margin-right: 8px;
    }
    .name {
      font-size: 16px;
    }
  }
  &__menu-box {
    position: absolute;
    width: 300px;
    right: -10px;
    background-color: #1b4332;
    border-radius: 10px;
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.2);
    transition: all 0.3s;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;
    visibility: hidden;
    opacity: 0;
    margin-top: 10px;
    padding: 20px;
    &:before {
      content: '';
      background-color: transparent;
      position: absolute;
      border-bottom: 12px solid #1b4332;
      border-right: 12px solid transparent;
      border-left: 12px solid transparent;
      border-top: 12px solid transparent;
      top: -24px;
      right: 20%;
    }
    &::after {
      content: '';
      background-color: transparent;
    }
    &-left {
      border-right: 1px solid #e0e2e9;
    }
    &-right {
      background-color: #081c15;
      border-radius: 25px;
    }
    &-title {
      color: $secondary;
      text-transform: capitalize;
      text-align: center;
      padding: 6px;
      margin-bottom: 10px;
      position: relative;
      .title-link,
      .title-link:hover {
        color: $secondary;
        text-decoration: none;
        .name {
          font-size: 18px;
        }
      }

      .title-action {
        border: 1px solid #d8f3dc7d;
        padding: 8px;
        padding-right: 15px;
        // margin: 10px;
        border-radius: 5px;
        text-transform: uppercase;
        font-size: 10px;
        color: #8ba093;
        &:hover,
        &:focus {
          color: inherit;
          text-decoration: none;
        }
      }
      .title-logout {
        position: absolute;
        left: 10px;
        top: 10px;
      }
    }
  }
  &__menu {
    margin: 0;
    padding: 10px;
    list-style: none;
    // display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  &__item {
    font-size: 14px;
    padding: 12px 0px;
    margin: 8px;
    text-align: left;
    font-weight: 500;
    // background: red;
    color: #ffffff;
    position: relative;
    border: 1px solid transparent;
    &:hover {
      background-color: #2d6a4f;
      border: 1px solid #d8f3dc50;
      border-radius: 5px;
    }
    .item-link {
      color: #ffffff;
      // font-size: 12px !important;
      &:hover,
      &:focus {
        color: #ffffff;
        outline: none;
        text-decoration: none;
      }
    }
    .item-icon {
      text-align: left;
      display: inline-block;
      margin-bottom: 8px;
      transition: all 0.4s;
      -webkit-transition: all 0.4s;
      -moz-transition: all 0.4s;
      -ms-transition: all 0.4s;
      -o-transition: all 0.4s;
    }
  }
}

.user-menu--active .user-menu__menu-box {
  visibility: visible;
  opacity: 1;
  margin-top: 15px;
}

.workspace-marker--dark {
  background: #0f251c;
}
</style>
