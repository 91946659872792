<template>
  <main>
    <div class="menu-box">
      <div class="menu-box-header">
        <div style="display: flex; " class="  justify-content-between">
          <div class="d-flex align-items-center">
            <svg-icon name="mic-icon" class="mr-1 text-primary" size="md" />

            <span>What's New</span>
          </div>
          <span>
            <svg-icon @click="closeMenu()" name="close-alt" class="mr-1 text-primary cursor-pointer" size="sm" />
          </span>
        </div>
      </div>
      <div class="menu-box-container">
        <div class="menu-box-content">
          <h5>AI VideoCreator Beta 2.0</h5>
          <p>
            Use AI to create stunning Videos for Social Media shorts, Youtube or any type of videos with just a Keyword
          </p>
          <p class="sub-heading"><span style="font-weight: bold"> Release Date:</span> <span>This Winter</span></p>
          <p class="sub-heading">
            <span style="font-weight: bold">Status:</span>
            <span> Originally Launched but back to BETA for a final release</span>
          </p>
          <div class="announcement-image">
            <img src="../../../assets/images/roadmaps/ai-video.png" alt="" />
          </div>
          <p>
            The Ultimate Video Creation Suite to create high engaging videos from a simple prompt. Our AI Video creator
            can create unlimited number of videos for any use-case. It generates video scripts, Creates scenes,
            Generates Images and Video clips and Outputs the entire video from a single prompt. Awesome right?
          </p>
          <span style="font-weight: bold">Here's a quick Overview </span>
          <ul style="padding-left: 40px; font-weight: 400;">
            <li>AI Video Creator</li>
            <li>Supports Vertical and Landscape Videos</li>
            <li>Ideal for any type of Video</li>
            <li>Creates Videos in less than 2 minutes</li>
            <li>No Camera or Editing needed</li>
          </ul>
        </div>
        <div class="menu-box-content">
          <h5>Tik Tok, Youtube and Instagram Reel Publishing</h5>

          <p class="sub-heading"><span style="font-weight: bold"> Release Date:</span> <span>This winter</span></p>
          <p class="sub-heading">
            <span style="font-weight: bold">Status:</span>
            <span> Currently in Closed Beta-Testing</span>
          </p>
          <div class="announcement-image">
            <img src="../../../assets/images/roadmaps/social-media.png" alt="" />
          </div>
          <p>
            Direct Publishing of vertical videos to Instagram, tiktok, youtube shorts/reels
          </p>
        </div>
        <div class="menu-box-content">
          <h5>Premium Video Template (Sorted by Niches & Usecase)</h5>

          <p class="sub-heading"><span style="font-weight: bold"> Release Date:</span> <span>December</span></p>
          <p class="sub-heading">
            <span style="font-weight: bold">Status:</span>
            <span> In Development</span>
          </p>
          <div class="announcement-image">
            <img src="../../../assets/images/roadmaps/Templates-Header.png" alt="" />
          </div>
          <p>
            Access to a Library of Done-For-You Video Templates across Niches, Use Cases and Video Types
          </p>
          <span style="font-weight: bold">Here's a quick Overview </span>
          <ul style="padding-left: 40px; font-weight: 400;">
            <li>Professionally designed by our in-house design team</li>
            <li>Done For you for different niches, use-cases for incredible speed</li>
            <li>Properly categorised</li>
            <li>Different templates for landscape, vertical etc</li>
          </ul>
        </div>
        <div class="menu-box-content">
          <h5>Social Inbox</h5>
          <p>
            Use AI to create stunning Videos for Social Media shorts, Youtube or any type of videos with just a Keyword
          </p>
          <p class="sub-heading"><span style="font-weight: bold"> Release Date:</span> <span>June 2025</span></p>

          <div class="announcement-image">
            <img src="../../../assets/images/roadmaps/socialpilot-Inbox.png" alt="" />
          </div>
          <p>
            Stay on top of your comments, mentions, and DMs, and never miss a conversation again, with Simplified's
            unified Social Inbox.
          </p>
          <span style="font-weight: bold">Here's a quick Overview </span>
          <ul style="padding-left: 40px; font-weight: 400;">
            <li>
              Browse and reply to all of your social media messages, comments, & mentions in one place: Interact with
              your customers, publicly and privately, from one easy-to-manage Social Inbox. Quickly review comments,
              mentions, tags, and other interactions from one consolidated Stream, and easily respond by clicking any
              notification to expand the conversation.
            </li>
            <li>
              All of your connected social media accounts will be displayed at the top of the screen. Select the
              profiles for the notifications you want to appear in your Stream or deselect to remove.
            </li>
            <li>
              Preview all of your notifications in the Stream feed, and choose what you'd like to do next: go to
              conversation, mark as resolved, open the message on the social network or expand the message preview.
            </li>
            <li>
              To respond to a notification, select 'Go to conversation'. The message will open in the Conversation tab
              where you can see the commenter's profile, reply, and share links and images.
            </li>
          </ul>
        </div>
        <div class="menu-box-content">
          <h5>Integrated Payments and Invoicing System for Agencies</h5>

          <p class="sub-heading"><span style="font-weight: bold"> Release Date:</span> <span>This Winter</span></p>
          <p class="sub-heading">
            <span style="font-weight: bold">Status:</span>
            <span> Currently in Closed Beta-Testing</span>
          </p>
          <div class="announcement-image">
            <img src="../../../assets/images/roadmaps/payment.jpg" alt="" />
          </div>
          <p>
            SocialAgency currently offer a standalone tool for billing, invoicing and so much more. But with this
            feature we are doing a 100% deep integration on the primary app to better handle invoicing and payments for
            agencies
          </p>
          <span style="font-weight: bold">Here's a quick Overview </span>
          <ul style="padding-left: 40px; font-weight: 400;">
            <li>Send manage Invoices</li>
            <li>Send and Manage Receipts</li>
            <li>Collect Payments from all the major gateways</li>
            <li>Manage financial reports</li>
            <li>Send automated reminders for invoicing, payments and more</li>
          </ul>
        </div>
        <div class="menu-box-content">
          <h5>AI Avatar Video Creator (Your Own Spokesperson)</h5>
          <p>
            Use AI to create stunning Videos for Social Media shorts, Youtube or any type of videos with just a Keyword
          </p>
          <p class="sub-heading"><span style="font-weight: bold"> Release Date:</span> <span> Winter, 2025</span></p>
          <p class="sub-heading">
            <span style="font-weight: bold">Status:</span>
            <span> Development</span>
          </p>
          <div class="announcement-image">
            <img src="../../../assets/images/roadmaps/ai-avatar.png" alt="" />
          </div>
          <p>
            This is a New Type of Video that will Create AI Avatars for you to create any type of tal;king Videos. These
            can be used to create extreme personalised videos with a face for you to promote a business, product or
            service. You can also use this for webinars, Trainings etc. The Value here is insane.
          </p>
          <span style="font-weight: bold">Quick Highlights </span>
          <ul style="padding-left: 40px; font-weight: 400;">
            <li>Get your life-like social media Spokesperson</li>
            <li>Create AI Video Avatars</li>
            <li>Upload your Image or use our library of Avatars</li>
            <li>Clone your Voice to create videos with your voice</li>
            <li>Sell and Profit with Personality without been on camera</li>
          </ul>
        </div>
      </div>
    </div>
  </main>
</template>
<script>
export default {
  data() {
    return {
      showAnnoucement: false,
    };
  },
  methods: {
    closeMenu() {
      this.$emit('close');
    },
  },
};
</script>
<style lang="scss" scoped>
// @import '~@/scss/variables';
.menu-box {
  position: absolute;

  // left: -300px;
  right: 40px;
  width: 100%;
  min-width: 500px;
  max-width: 500px;
  min-height: 600px;
  background-color: #b7e4c7;
  border-radius: 10px;
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.2);
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  //   visibility: hidden;
  opacity: 1;
  margin-top: 12px;
  &:before {
    content: '';
    background-color: transparent;
    position: absolute;
    border-bottom: 12px solid #b7e4c7;
    border-right: 12px solid transparent;
    border-left: 12px solid transparent;
    border-top: 12px solid transparent;
    top: -22px;
    right: 35%;
  }
  &::after {
    content: '';
    background-color: transparent;
  }
}
.menu-box-header {
  padding: 20px;
  border-bottom: 0.5px solid rgb(188, 184, 184);
}
.menu-box-container {
  height: 600px;
  overflow-y: scroll;
  border-bottom: 0.5px solid rgb(188, 184, 184);
}
.menu-box-container:last-child {
  border-bottom: none;
}
.menu-box-content {
  padding: 20px;

  border-bottom: 0.5px solid rgb(188, 184, 184);
}
.menu-box-content p {
  font-weight: 400;
  margin-bottom: 10px;
  text-align: justify;
}
.menu-box-content li {
  text-align: justify;
  margin-bottom: 10px;
}

.announcement-image {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 0;
}
.announcement-image img {
  width: 100%;
  height: auto;
  object-fit: cover;
}
.sub-heading {
  font-size: 14px;
}
</style>
