import gql from 'graphql-tag';

import {
  USER_FIELDS_FRAGMENT,
  POST_FIELDS_FRAGMENT,
  BUSINESS_FIELDS_FRAGMENT,
  WORKSPACE_FIELDS_FRAGMENT,
  AUTOMATION_FIELDS_FRAGMENT,
  UTM_PRESET_FIELDS_FRAGMENT,
  TEAM_MEMBER_FIELDS_FRAGMENT,
  PAYMENT_METHOD_FIELDS_FRAGMENT,
  HASHTAG_PRESET_FIELDS_FRAGMENT,
  PUBLISH_ACCOUNT_FIELDS_FRAGMENT,
  DESIGN_TEMPLATE_FIELDS_FRAGMENT,
  USER_SUBSCRIPTION_FIELDS_FRAGMENT,
  DESIGN_TEMPLATE_CATEGORY_FIELDS_FRAGMENT,
  POST_TASK_FIELDS_FRAGMENT,
  POST_COMMENT_FIELDS_FRAGMENT,
  FOLDER_FIELDS_FRAGMENT,
  LEAD_FIELDS_FRAGMENT,
} from '~/graphql/fragments';

export const AI_GENERATE_SCRIPT_MUTATION = gql`
  mutation GenerateVideoScript($message: String!, $id: Int, $workspace_id: Int, $count: Int) {
    generateVideoScript(message: $message, id: $id, workspace_id: $workspace_id, count: $count, context: []) {
      id
      status
      scripts {
        scene
        description
        displaytext
        voiceover
      }
    }
  }
`;

export const AI_SAVE_SCRIPT_MUTATION = gql`
  mutation SaveVideoWizardStep(
    $title: String
    $background: String
    $canvas: String
    $template_id: Int
    $thumbnail: String
    $scripts: [GenerateScriptInput]
  ) {
    saveVideoWizardStep(
      title: $title
      background: $background
      canvas: $canvas
      template_id: $template_id
      thumbnail: $thumbnail
      scripts: $scripts
    ) {
      id
    }
  }
`;

export const ADD_DFY_WEBSITE_MUTATION = gql`
  mutation {
    addDfyWebsite {
      name
      sub_domain
      id
    }
  }
`;

export const UPDATE_DFY_WEBSITE_MUTATION = gql`
  mutation UpdateDfyWebsite(
    $id: Int!
    $custom_javascript: String
    $custom_domain: String
    $logo: Upload
    $favicon: Upload
    $name: String
    $support_email: String
    $template_cover: String
    $template_id: String
    $contact_email: String
    $address: String
    $facebook: String
    $linkedin: String
    $twitter: String
    $instagram: String
  ) {
    updateDfyWebsite(
      id: $id
      logo: $logo
      custom_javascript: $custom_javascript
      custom_domain: $custom_domain
      favicon: $favicon
      name: $name
      support_email: $support_email
      template_cover: $template_cover
      template_id: $template_id
      contact_email: $contact_email
      instagram: $instagram
      twitter: $twitter
      linkedin: $linkedin
      facebook: $facebook
      address: $address
    ) {
      id
      name
      support_email
    }
  }
`;

export const DELETE_DFY_WEBSITE_MUTATION = gql`
  mutation DeleteDfyWebsite($id: Int) {
    deleteDfyWebsite(id: $id)
  }
`;

export const AI_POST_MESSAGE_MUTATION = gql`
  mutation AiPost($message: String!, $postType: String!, $context: [AiPostContextInput]) {
    aiPost(message: $message, postType: $postType, context: $context) {
      response
    }
  }
`;

export const AI_AUTOMATED_POST_MESSAGE_MUTATION = gql`
  mutation AiFullPost($message: String!, $postType: String!, $context: [String]) {
    aiFullPost(message: $message, postType: $postType, context: $context) {
      response {
        title
        content
        cover_image
      }
    }
  }
`;

export const AI_IMAGE_MESSAGE_MUTATION = gql`
  mutation AiImage($message: String!) {
    aiImage(message: $message) {
      response
    }
  }
`;

export const SIGN_UP_MUTATION = gql`
  mutation SignUpMutation(
    $id: String
    $type: String
    $firstName: String!
    $lastName: String
    $email: String!
    $password: String!
    $pc: String
  ) {
    signUp(
      id: $id
      type: $type
      firstName: $firstName
      lastName: $lastName
      email: $email
      password: $password
      pc: $pc
    ) {
      token
      code
      expires_at
    }
  }
`;

export const LOGIN_MUTATION = gql`
  mutation LogInMutation($email: String!, $password: String!) {
    token: logIn(email: $email, password: $password)
  }
`;

export const SSO_SIGN_IN_MUTATION = gql`
  mutation SsoSignInMutation($id: String!, $type: String!, $email: String!) {
    token: ssoSignInMutation(id: $id, type: $type, email: $email)
  }
`;

export const RESEND_VERIFY_EMAIL_MUTATION = gql`
  mutation ResendVerifyEmailMutation {
    resendVerifyEmail
  }
`;

export const SEND_PASSWORD_RESET_EMAIL_MUTATION = gql`
  mutation SendPasswordResetEmailMutation($email: String!) {
    sendPasswordResetEmail(email: $email)
  }
`;

export const RESET_PASSWORD_MUTATION = gql`
  mutation ResetPasswordMutation(
    $email: String!
    $token: String!
    $password: String!
    $password_confirmation: String!
  ) {
    resetPassword(email: $email, token: $token, password: $password, password_confirmation: $password_confirmation)
  }
`;

export const VERIFY_USER_EMAIL_MUTATION = gql`
  mutation ResendVerifyEmailMutation($token: String!) {
    verifyUserEmail(token: $token) {
      ...UserFields
    }
  }
  ${USER_FIELDS_FRAGMENT}
`;

export const LOGOUT_MUTATION = gql`
  mutation LogOutMutation {
    logOut
  }
`;

export const SAVE_GIF_CONTENT_MUTATION = gql`
  mutation SaveGiphyContent($keyword: String!, $image_url: String!) {
    saved: saveGiphyContent(keyword: $keyword, image_url: $image_url) {
      id
    }
  }
`;

export const SAVE_QUOTE_CONTENT_MUTATION = gql`
  mutation SaveQuoteContent($keyword: String!, $content: String!) {
    saved: saveQuoteContent(keyword: $keyword, content: $content) {
      id
    }
  }
`;

export const SAVE_LINK_CONTENT_MUTATION = gql`
  mutation SaveLinkContent($keyword: String!, $content: String!) {
    saved: saveLinkContent(keyword: $keyword, content: $content) {
      id
    }
  }
`;

export const SAVE_BANNER_DESIGN_MUTATION = gql`
  mutation SaveBannerDesignMutation($workspace: Int!, $id: Int!, $canvasData: String!, $dataURL: String!) {
    saved: saveBannerDesign(workspace: $workspace, id: $id, canvasData: $canvasData, dataURL: $dataURL) {
      id
      imageUrl
      canvasData
      thumb: imageUrl(size: "sm")
    }
  }
`;

export const SHARE_DESIGN_MUTATION = gql`
  mutation ShareDesignMutation(
    $workspace: Int!
    $design: Int!
    $platform: String!
    $accountId: Int!
    $caption: String!
  ) {
    shareDesign(workspace: $workspace, design: $design, platform: $platform, accountId: $accountId, caption: $caption) {
      ...PostFields
    }
  }
  ${POST_FIELDS_FRAGMENT}
`;

export const DELETE_BANNER_DESIGN_MUTATION = gql`
  mutation DeleteBannerDesignMutation($workspace: Int!, $id: Int!) {
    deleteBannerDesign(workspace: $workspace, id: $id) {
      id
      imageUrl
      canvasData
      thumb: imageUrl(size: "sm")
    }
  }
`;

export const SAVE_DESIGN_TEMPLATE_CATEGORY_MUTATION = gql`
  mutation SaveDesignCategoryMutation($id: Int!, $title: String!, $description: String!) {
    saveDesignTemplateCategory(id: $id, title: $title, description: $description) {
      ...DesignTemplateCategoryFields
    }
  }
  ${DESIGN_TEMPLATE_CATEGORY_FIELDS_FRAGMENT}
`;

export const SAVE_DESIGN_TEMPLATE_MUTATION = gql`
  mutation SaveDesignTemplateMutation(
    $id: Int!
    $category: Int!
    $tags: [String]!
    $canvasData: String!
    $dataURL: String!
  ) {
    saveDesignTemplate(id: $id, category: $category, tags: $tags, canvasData: $canvasData, dataURL: $dataURL) {
      ...DesignTemplateFields
    }
  }
  ${DESIGN_TEMPLATE_FIELDS_FRAGMENT}
`;

export const DELETE_DESIGN_TEMPLATE_MUTATION = gql`
  mutation DeleteDesignTemplateMutation($id: Int!) {
    deleteDesignTemplate(id: $id) {
      ...DesignTemplateFields
    }
  }
  ${DESIGN_TEMPLATE_FIELDS_FRAGMENT}
`;

export const SAVE_EDITOR_CONTENT_MUTATION = gql`
  mutation SaveEditorContent($workspace: Int!, $id: Int!, $content: String!) {
    saved: saveEditorContent(workspace: $workspace, id: $id, content: $content) {
      id
    }
  }
`;

export const EXPORT_EDITOR_CONTENT_AS_PDF_MUTATION = gql`
  mutation ExportEditorContentAsPdf($workspace: Int!, $id: Int!, $content: String!) {
    downloadUrl: exportEditorContentAsPdf(workspace: $workspace, id: $id, content: $content)
  }
`;

export const DELETE_EDITOR_CONTENT_MUTATION = gql`
  mutation DeleteEditorContent($workspace: Int!, $ids: [Int]!) {
    deleted: deleteEditorContent(workspace: $workspace, ids: $ids)
  }
`;

export const DELETE_PUBLISH_ACCOUNT = gql`
  mutation DeletePublishAccount($workspace: Int!, $ids: [Int]!) {
    deleted: deletePublishAccount(workspace: $workspace, ids: $ids)
  }
`;

export const SAVE_POST_MUTATION = gql`
  mutation SavePostMutation(
    $workspace: Int!
    $id: Int!
    $type: String!
    $campaignId: Int
    $masterContent: String
    $status: String
    $scheduleTime: String
    $scheduleTimezone: String
    $accounts: [PostContentInput]!
    $attachments: [PostAttachmentInput]
    $publishNow: Boolean
    $featuredImageLink: String
  ) {
    saved: savePost(
      workspace: $workspace
      id: $id
      type: $type
      campaignId: $campaignId
      masterContent: $masterContent
      status: $status
      scheduleTime: $scheduleTime
      scheduleTimezone: $scheduleTimezone
      accounts: $accounts
      attachments: $attachments
      publishNow: $publishNow
      featuredImageLink: $featuredImageLink
    ) {
      ...PostFields
    }
  }
  ${POST_FIELDS_FRAGMENT}
`;

export const SAVE_AUDIO_POST_MUTATION = gql`
  mutation SaveAudioPostMutation($workspace: Int!, $id: Int!, $text: String!, $voice: String!) {
    saveAudioPost(workspace: $workspace, id: $id, text: $text, voice: $voice) {
      ...PostFields
    }
  }
  ${POST_FIELDS_FRAGMENT}
`;

export const FINALIZE_AUDIO_POST_MUTATION = gql`
  mutation FinalizeAudioPostMutation(
    $workspace: Int!
    $id: Int!
    $status: String!
    $scheduleTime: String!
    $scheduleTimezone: String!
    $accounts: [PostContentInput]!
    $publishNow: Boolean
  ) {
    finalizeAudioPost(
      workspace: $workspace
      id: $id
      status: $status
      scheduleTime: $scheduleTime
      scheduleTimezone: $scheduleTimezone
      accounts: $accounts
      publishNow: $publishNow
    ) {
      ...PostFields
    }
  }
  ${POST_FIELDS_FRAGMENT}
`;

export const SAVE_AUTOMATION_MUTATION = gql`
  mutation SaveAutomationMutation(
    $workspace: Int!
    $id: Int!
    $type: String!
    $recipe: String!
    $name: String!
    $hashtagPreset: Int
    $source: String
    $recurringValue: Int
    $recurringUnit: String
    $scheduleType: String
    $startDate: String
    $endDate: String
    $reviewPosts: Boolean
    $removePosts: Boolean
    $maxPostCount: Int
    $prefetchDaysLimit: Int
    $filters: AutomationFiltersInput
    $status: String
    $accounts: [PublishingParamsInput]
    $csvFile: Upload
    $csvDateFormat: String
    $aiPostPrompt: String
    $count_per_request: Int
  ) {
    saved: saveAutomation(
      workspace: $workspace
      id: $id
      type: $type
      recipe: $recipe
      name: $name
      hashtagPreset: $hashtagPreset
      source: $source
      recurringValue: $recurringValue
      recurringUnit: $recurringUnit
      scheduleType: $scheduleType
      startDate: $startDate
      endDate: $endDate
      reviewPosts: $reviewPosts
      removePosts: $removePosts
      maxPostCount: $maxPostCount
      prefetchDaysLimit: $prefetchDaysLimit
      filters: $filters
      status: $status
      accounts: $accounts
      csvFile: $csvFile
      csvDateFormat: $csvDateFormat
      aiPostPrompt: $aiPostPrompt
      count_per_request: $count_per_request
    ) {
      ...AutomationFields
    }
  }
  ${AUTOMATION_FIELDS_FRAGMENT}
`;

export const CHANGE_AUTOMATION_STATUS_MUTATION = gql`
  mutation ChangeAutomationStatusMutation($workspace: Int!, $ids: [Int]!, $status: String) {
    changed: changeAutomationStatus(workspace: $workspace, ids: $ids, status: $status) {
      ...AutomationFields
    }
  }
  ${AUTOMATION_FIELDS_FRAGMENT}
`;

export const REMOVE_AUTOMATION_MUTATION = gql`
  mutation RemoveAutomationMutation($workspace: Int!, $ids: [Int]!) {
    deleted: removeAutomation(workspace: $workspace, ids: $ids)
  }
`;

export const CREATE_CAMPAIGN_MUTATION = gql`
  mutation CreateCampaignMutation($workspace: Int!, $title: String!) {
    saved: saveCampaign(workspace: $workspace, title: $title) {
      id
    }
  }
`;

export const CHANGE_CAMPAIGN_STATUS_MUTATION = gql`
  mutation ChangeCampaignStatusMutation($workspace: Int!, $id: Int!, $status: String!) {
    changeCampaignStatus(workspace: $workspace, id: $id, status: $status) {
      id
    }
  }
`;

export const DELETE_CAMPAIGN_MUTATION = gql`
  mutation DeleteCampaign($workspace: Int!, $ids: [Int]!) {
    deleted: deleteCampaign(workspace: $workspace, ids: $ids)
  }
`;

export const DELETE_CAMPAIGN_POSTS_MUTATION = gql`
  mutation DeleteCampaignPostsMutation($workspace: Int!, $ids: [Int]!) {
    deleted: deleteCampaignPost(workspace: $workspace, ids: $ids)
  }
`;

export const SAVE_SELECTED_FB_ACCOUNTS_MUTATION = gql`
  mutation SaveSelectedFbAccounts($workspace: Int!, $accountId: String!, $pageIds: [String], $groupIds: [String]) {
    savedAccounts: saveSelectedFbAccounts(
      workspace: $workspace
      accountId: $accountId
      pageIds: $pageIds
      groupIds: $groupIds
    ) {
      ...PublishAccountFields
    }
  }
  ${PUBLISH_ACCOUNT_FIELDS_FRAGMENT}
`;

export const SAVE_SELECTED_INSTAGRAM_ACCOUNTS_MUTATION = gql`
  mutation saveSelectedInstagramAccounts($workspace: Int!, $accountId: Int!, $instagramId: String!) {
    savedAccounts: saveSelectedInstagramAccounts(
      workspace: $workspace
      accountId: $accountId
      instagramId: $instagramId
    ) {
      ...PublishAccountFields
    }
  }
  ${PUBLISH_ACCOUNT_FIELDS_FRAGMENT}
`;

export const SAVE_SELECTED_PINTEREST_BOARDS_MUTATION = gql`
  mutation SaveSelectedPinterestBoards($workspace: Int!, $accountId: String!, $boardIds: [String]) {
    savedAccounts: saveSelectedPinterestBoards(workspace: $workspace, accountId: $accountId, boardIds: $boardIds) {
      ...PublishAccountFields
    }
  }
  ${PUBLISH_ACCOUNT_FIELDS_FRAGMENT}
`;

export const SAVE_SELECTED_LINKEDIN_ORGS_MUTATION = gql`
  mutation SaveSelectedLinkedinOrgs($workspace: Int!, $accountId: String!, $orgIds: [String]) {
    savedAccounts: saveSelectedLinkedinOrganisations(workspace: $workspace, accountId: $accountId, orgIds: $orgIds) {
      ...PublishAccountFields
    }
  }
  ${PUBLISH_ACCOUNT_FIELDS_FRAGMENT}
`;

export const CONNECT_WORDPRESS_SITE_MUTATION = gql`
  mutation ConnectWordpressMutation($workspace: Int!, $url: String!, $username: String!, $password: String!) {
    savedAccount: connectWordpress(workspace: $workspace, url: $url, username: $username, password: $password) {
      ...PublishAccountFields
    }
  }
  ${PUBLISH_ACCOUNT_FIELDS_FRAGMENT}
`;

export const CONNECT_MEDIUM_BLOG_MUTATION = gql`
  mutation ConnectMediumMutation($workspace: Int!, $token: String!) {
    savedAccount: connectMedium(workspace: $workspace, token: $token) {
      status
      url
    }
  }
`;

export const SAVE_SELECTED_MEDIUM_PUBLICATIONS_MUTATION = gql`
  mutation SaveSelectedMediumPublications($workspace: Int!, $accountId: String!, $publicationIds: [String]) {
    savedAccounts: saveSelectedMediumPublications(
      workspace: $workspace
      accountId: $accountId
      publicationIds: $publicationIds
    ) {
      ...PublishAccountFields
    }
  }
  ${PUBLISH_ACCOUNT_FIELDS_FRAGMENT}
`;

export const SAVE_SELECTED_TUMBLR_BLOGS_MUTATION = gql`
  mutation SaveSelectedTumblrBlogs($workspace: Int!, $accountId: String!, $blogIds: [String]) {
    savedAccounts: saveSelectedTumblrBlogs(workspace: $workspace, accountId: $accountId, blogIds: $blogIds) {
      ...PublishAccountFields
    }
  }
  ${PUBLISH_ACCOUNT_FIELDS_FRAGMENT}
`;

export const CHANGE_POST_STATUS_MUTATION = gql`
  mutation ChangePostStatusMutation($workspace: Int!, $ids: [Int], $status: String) {
    changedPosts: changePostStatus(workspace: $workspace, ids: $ids, status: $status) {
      ...PostFields
    }
  }
  ${POST_FIELDS_FRAGMENT}
`;

export const DELETE_POST_MUTATION = gql`
  mutation DeletePostMutation($workspace: Int!, $ids: [Int]!) {
    deleted: deletePost(workspace: $workspace, ids: $ids)
  }
`;

export const CHANGE_ATTACH_UTM_STATUS_MUTATION = gql`
  mutation ChangeAttachUtmStatusMutation($workspace: Int!, $status: Boolean!) {
    changeAttachUtmStatus(workspace: $workspace, status: $status) {
      ...WorkspaceFields
    }
  }
  ${WORKSPACE_FIELDS_FRAGMENT}
`;

export const CHANGE_DEFAULT_UTM_MUTATION = gql`
  mutation ChangeDefaultUtmMutation($workspace: Int!, $utm: Int!) {
    changeDefaultUtm(workspace: $workspace, utm: $utm) {
      ...UtmPresetFields
    }
  }
  ${UTM_PRESET_FIELDS_FRAGMENT}
`;

export const SAVE_UTM_PRESET_MUTATION = gql`
  mutation SaveUtmPresetMutation(
    $workspace: Int!
    $id: Int!
    $name: String!
    $medium: String!
    $source: String
    $autoAddSocialChannel: Boolean!
  ) {
    saved: saveUtmPreset(
      workspace: $workspace
      id: $id
      name: $name
      medium: $medium
      source: $source
      autoAddSocialChannel: $autoAddSocialChannel
    ) {
      ...UtmPresetFields
    }
  }
  ${UTM_PRESET_FIELDS_FRAGMENT}
`;

export const DELETE_UTM_PRESET_MUTATION = gql`
  mutation DeleteUtmPresetMutation($workspace: Int!, $id: Int!) {
    deleted: deleteUtmPreset(workspace: $workspace, id: $id) {
      ...UtmPresetFields
    }
  }
  ${UTM_PRESET_FIELDS_FRAGMENT}
`;

export const SAVE_HASHTAG_PRESET_MUTATION = gql`
  mutation SaveHashtagPresetMutation(
    $workspace: Int!
    $id: Int!
    $name: String!
    $hashtags: [String]!
    $insertCount: Int!
    $insertType: String!
  ) {
    saved: saveHashtagPreset(
      workspace: $workspace
      id: $id
      name: $name
      hashtags: $hashtags
      insertCount: $insertCount
      insertType: $insertType
    ) {
      ...HashtagPresetFields
    }
  }
  ${HASHTAG_PRESET_FIELDS_FRAGMENT}
`;

export const DELETE_HASHTAG_PRESET_MUTATION = gql`
  mutation DeleteHashtagPresetMutation($workspace: Int!, $id: Int!) {
    deleted: deleteHashtagPreset(workspace: $workspace, id: $id) {
      ...HashtagPresetFields
    }
  }
  ${HASHTAG_PRESET_FIELDS_FRAGMENT}
`;

export const SAVE_USER_UPLOAD_MUTATION = gql`
  mutation SaveUserUploadMutation($workspace: Int!, $upload: Upload!) {
    saveUserUpload(workspace: $workspace, upload: $upload) {
      id
      url
      thumb
      type
      status
    }
  }
`;

export const DELETE_USER_UPLOAD_MUTATION = gql`
  mutation DeleteUserUploadMutation($workspace: Int!, $ids: [Int]!) {
    deleted: deleteUserUpload(workspace: $workspace, ids: $ids)
  }
`;

export const SUBSCRIBE_TO_PLAN_MUTATION = gql`
  mutation SubscribeToPlanMutation($paymentMethod: String!, $planId: String!) {
    subscribeToPlan(paymentMethod: $paymentMethod, planId: $planId) {
      ...UserFields
    }
  }
  ${USER_FIELDS_FRAGMENT}
`;

export const UPDATE_USER_PROFILE_MUTATION = gql`
  mutation UpdateUserProfileMutation($firstName: String!, $lastName: String!, $email: String!, $avatar: Upload) {
    updateUserProfile(firstName: $firstName, lastName: $lastName, email: $email, avatar: $avatar) {
      ...UserFields
    }
  }
  ${USER_FIELDS_FRAGMENT}
`;

export const UPDATE_USER_PASSWORD_MUTATION = gql`
  mutation UpdateUserPasswordMutation($password: String!, $new_password: String!, $new_password_confirmation: String!) {
    updateUserPassword(
      password: $password
      new_password: $new_password
      new_password_confirmation: $new_password_confirmation
    ) {
      ...UserFields
    }
  }
  ${USER_FIELDS_FRAGMENT}
`;

export const SAVE_USER_OPTIONS_MUTATION = gql`
  mutation SaveUserOptionsMutation($options: UserOptionsInput!) {
    user: saveUserOptions(options: $options) {
      ...UserFields
    }
  }
  ${USER_FIELDS_FRAGMENT}
`;

export const CANCEL_SUBSCRIPTION_MUTATION = gql`
  mutation CancelSubscriptionMutation {
    cancelSubscription {
      ...UserFields
    }
  }
  ${USER_FIELDS_FRAGMENT}
`;

export const DELETE_PAYMENT_METHOD_MUTATION = gql`
  mutation DeletePaymentMethodMutation($id: String!) {
    deleted: deletePaymentMethod(id: $id)
  }
`;

export const UPDATE_DEFAULT_PAYMENT_METHOD_MUTATION = gql`
  mutation UpdateDefaultPaymentMethodMutation($id: String!) {
    updated: updateDefaultPaymentMethod(id: $id)
  }
`;

export const ADD_PAYMENT_METHOD_MUTATION = gql`
  mutation AddPaymentMethodMutation($id: String!) {
    addPaymentMethod(id: $id) {
      ...PaymentMethodFields
    }
  }
  ${PAYMENT_METHOD_FIELDS_FRAGMENT}
`;

export const SAVE_BUSINESS_MUTATION = gql`
  mutation SaveBusinessMutation(
    $id: Int
    $name: String!
    $address: String!
    $phone: String!
    $logo: String!
    $slug: String!
    $description: String!
    $timezone: String!
  ) {
    saveBusiness(
      id: $id
      name: $name
      address: $address
      phone: $phone
      logo: $logo
      slug: $slug
      description: $description
      timezone: $timezone
    ) {
      ...BusinessFields
    }
  }
  ${BUSINESS_FIELDS_FRAGMENT}
`;

export const UPLOAD_WORKSPACE_LOGO_MUTATION = gql`
  mutation UploadWorkspaceLogoMutation($logo: Upload!) {
    uploadWorkspaceLogo(logo: $logo) {
      url
    }
  }
`;

export const SAVE_WORKSPACE_MUTATION = gql`
  mutation SaveWorkspaceMutation(
    $id: Int
    $name: String!
    $slug: String!
    $timezone: String!
    $logo: String!
    $notes: String
  ) {
    saveWorkspace(id: $id, name: $name, slug: $slug, timezone: $timezone, logo: $logo, notes: $notes) {
      ...WorkspaceFields
    }
  }
  ${WORKSPACE_FIELDS_FRAGMENT}
`;

export const DELETE_WORKSPACE_MUTATION = gql`
  mutation DeleteWorkspaceMutation($id: Int!) {
    deleteWorkspace(id: $id)
  }
`;

export const ADD_TEAM_MEMBER_MUTATION = gql`
  mutation AddTeamMemberMutation(
    $firstName: String!
    $lastName: String!
    $email: String!
    $type: String!
    $role: String!
    $workspaces: [Int]!
  ) {
    addTeamMember(
      firstName: $firstName
      lastName: $lastName
      email: $email
      type: $type
      role: $role
      workspaces: $workspaces
    ) {
      ...TeamMemberFields
    }
  }
  ${TEAM_MEMBER_FIELDS_FRAGMENT}
`;

export const UPDATE_TEAM_MEMBER_MUTATION = gql`
  mutation UpdateTeamMemberMutation($id: Int!, $type: String!, $role: String!, $workspace: Int!) {
    updateTeamMember(id: $id, type: $type, role: $role, workspace: $workspace) {
      ...TeamMemberFields
    }
  }
  ${TEAM_MEMBER_FIELDS_FRAGMENT}
`;

export const CHANGE_TEAM_MEMBER_ROLE_MUTATION = gql`
  mutation ChangeTeamMemberRoleMutation($workspaceId: Int!, $userId: Int!, $role: String!) {
    changeTeamMemberRole(workspaceId: $workspaceId, userId: $userId, role: $role) {
      ...TeamMemberFields
    }
  }
  ${TEAM_MEMBER_FIELDS_FRAGMENT}
`;

export const REMOVE_TEAM_MEMBER_MUTATION = gql`
  mutation RemoveTeamMemberMutation($workspaceIds: [Int]!, $userId: Int!) {
    removeTeamMember(workspaceIds: $workspaceIds, userId: $userId) {
      ...WorkspaceFields
    }
  }
  ${WORKSPACE_FIELDS_FRAGMENT}
`;

export const SET_DEFAULT_WORKSPACE_MUTATION = gql`
  mutation SetDefaultWorkspaceMutation($id: Int!) {
    setDefaultWorkspace(id: $id) {
      ...WorkspaceFields
    }
  }
  ${WORKSPACE_FIELDS_FRAGMENT}
`;

export const VALIDATE_JOIN_TOKEN_MUTATION = gql`
  mutation ValidateJoinTokenMutation($token: String!) {
    validateJoinToken(token: $token)
  }
`;

export const JOIN_AND_SET_PASSWORD_MUTATION = gql`
  mutation JoinAndSetPasswordMutation($token: String!, $password: String!, $password_confirmation: String!) {
    joinAndSetPassword(token: $token, password: $password, password_confirmation: $password_confirmation)
  }
`;

export const UPDATE_WORKSPACE_LINK_SHORTENER_MUTATION = gql`
  mutation UpdateWorkspaceLinkShortenerMutation($workspace: Int!, $linkShortener: String!) {
    updateWorkspaceLinkShortener(workspace: $workspace, linkShortener: $linkShortener) {
      ...WorkspaceFields
    }
  }
  ${WORKSPACE_FIELDS_FRAGMENT}
`;

export const UPDATE_WORKSPACE_BITLY_DOMAIN_MUTATION = gql`
  mutation UpdateWorkspaceBitlyDomainMutation($workspace: Int!, $domain: String!) {
    updateWorkspaceBitlyDomain(workspace: $workspace, domain: $domain) {
      ...WorkspaceFields
    }
  }
  ${WORKSPACE_FIELDS_FRAGMENT}
`;

export const DISCONNECT_WORKSPACE_BITLY_MUTATION = gql`
  mutation DisconnectWorkspaceBitlyMutation($workspace: Int!) {
    disconnectWorkspaceBitly(workspace: $workspace) {
      ...WorkspaceFields
    }
  }
  ${WORKSPACE_FIELDS_FRAGMENT}
`;

export const SAVE_YOUZIGN_CREDENTIALS_MUTATION = gql`
  mutation SaveYouzignCredentialsMutation($workspace: Int!, $key: String!, $token: String!) {
    saveYouzignCredentials(workspace: $workspace, key: $key, token: $token) {
      ...WorkspaceFields
    }
  }
  ${WORKSPACE_FIELDS_FRAGMENT}
`;

export const DISCONNECT_YOUZIGN_MUTATION = gql`
  mutation DisconnectYouzignMutation($workspace: Int!) {
    disconnectYouzign(workspace: $workspace) {
      ...WorkspaceFields
    }
  }
  ${WORKSPACE_FIELDS_FRAGMENT}
`;

export const SHORTEN_URL_MUTATION = gql`
  mutation ShortenUrlMutation($workspace: Int!, $url: String!) {
    shortenUrl(workspace: $workspace, url: $url)
  }
`;

export const ADD_RESELLER_USER_MUTATION = gql`
  mutation AddResellerUserMutation(
    $firstName: String!
    $lastName: String!
    $email: String!
    $password: String!
    $password_confirmation: String!
    $plan: String!
    $amount: Int!
  ) {
    addResellerUser(
      firstName: $firstName
      lastName: $lastName
      email: $email
      password: $password
      password_confirmation: $password_confirmation
      plan: $plan
      amount: $amount
    ) {
      ...UserFields
    }
  }
  ${USER_FIELDS_FRAGMENT}
`;

export const DELETE_RESELLER_USER_MUTATION = gql`
  mutation DeleteResellerUserMutation($id: Int!) {
    deleteResellerUser(id: $id)
  }
`;

export const UPDATE_RESELLER_USER_SUBSCRIPTION_MUTATION = gql`
  mutation UpdateResellerUserSubscriptionMutation($id: Int!, $plan: String!, $amount: Int!) {
    updateResellerUserSubscription(id: $id, plan: $plan, amount: $amount) {
      ...UserSubscriptionFields
    }
  }
  ${USER_SUBSCRIPTION_FIELDS_FRAGMENT}
`;

export const UPDATE_RESELLER_USER_MUTATION = gql`
  mutation UpdateResellerUserMutation($id: Int!, $firstName: String!, $lastName: String!, $email: String!) {
    updateResellerUser(id: $id, firstName: $firstName, lastName: $lastName, email: $email) {
      ...UserFields
    }
  }
  ${USER_FIELDS_FRAGMENT}
`;

export const GENERATE_RESELLER_WEBHOOK_URL_MUTATION = gql`
  mutation GenerateResellerWebhookUrlMutation($key: String!, $platform: String!) {
    generateResellerWebhookUrl(key: $key, platform: $platform) {
      lifetime
      monthly
      platform
    }
  }
`;

export const DELETE_RESELLER_WEBHOOK_URL_MUTATION = gql`
  mutation DeleteResellerWebhookUrlMutation($platform: String!) {
    deleteResellerWebhookUrl(platform: $platform)
  }
`;

export const EDIT_POST_TASK_MUTATION = gql`
  mutation EditPostTaskMutation($postId: Int!, $id: Int!, $data: PostTaskEditInput!) {
    editPostTask(postId: $postId, id: $id, data: $data) {
      ...PostTaskFields
    }
  }
  ${POST_TASK_FIELDS_FRAGMENT}
`;

export const DELETE_POST_TASK_MUTATION = gql`
  mutation DeletePostTaskMutation($postId: Int!, $id: Int!) {
    deletePostTask(postId: $postId, id: $id) {
      ...PostTaskFields
    }
  }
  ${POST_TASK_FIELDS_FRAGMENT}
`;

export const DELETE_ALL_POST_TASK_MUTATION = gql`
  mutation DeleteAllPostTaskMutation($postId: Int!) {
    deleted: deleteAllPostTask(postId: $postId)
  }
`;

export const ADD_POST_TASK_MUTATION = gql`
  mutation AddPostTaskMutation($postId: Int!, $text: String!) {
    addPostTask(postId: $postId, text: $text) {
      ...PostTaskFields
    }
  }
  ${POST_TASK_FIELDS_FRAGMENT}
`;

export const ADD_POST_COMMENT_MUTATION = gql`
  mutation AddPostCommentMutation($postId: Int!, $message: String!) {
    addPostComment(postId: $postId, message: $message) {
      ...PostCommentFields
    }
  }
  ${POST_COMMENT_FIELDS_FRAGMENT}
`;

export const ADD_POST_COMMENT_INSIGHT_MUTATION = gql`
  mutation AddPostCommentInsightMutation($postCommentId: Int!, $action: String!) {
    addPostCommentInsight(postCommentId: $postCommentId, action: $action) {
      id
      action
      isCurrentUser
      date
    }
  }
`;

export const DELETE_POST_COMMENT_INSIGHT_MUTATION = gql`
  mutation DeletePostCommentInsightMutation($postCommentInsightId: Int!) {
    deletePostCommentInsight(postCommentInsightId: $postCommentInsightId) {
      id
    }
  }
`;

export const UPDATE_POST_APPROVED_STATUS_MUTATION = gql`
  mutation UpdatePostApprovedStatusMutation($workspace: Int, $id: Int, $isApproved: Int!) {
    updatePostApprovedStatus(workspace: $workspace, id: $id, isApproved: $isApproved) {
      ...PostFields
    }
  }
  ${POST_FIELDS_FRAGMENT}
`;

export const SAVE_FOLDER_MUTATION = gql`
  mutation SaveFolderMutation($id: Int, $name: String!, $purpose: String) {
    saved: saveFolder(id: $id, name: $name, purpose: $purpose) {
      ...FolderFields
    }
  }
  ${FOLDER_FIELDS_FRAGMENT}
`;

export const SAVE_LEAD_MUTATION = gql`
  mutation SaveLeadMutation(
    $id: Int
    $firstName: String!
    $lastName: String!
    $email: String!
    $phoneCode: String
    $phoneNumber: String
    $address: String
    $title: String
    $company: String
    $domain: String
  ) {
    saved: saveLead(
      id: $id
      firstName: $firstName
      lastName: $lastName
      email: $email
      phoneCode: $phoneCode
      phoneNumber: $phoneNumber
      address: $address
      title: $title
      company: $company
      domain: $domain
    ) {
      ...LeadFields
    }
  }
  ${LEAD_FIELDS_FRAGMENT}
`;
