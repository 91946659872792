<template>
  <div class="notification-menu" :class="{ 'notification-menu--active': showNotificationMenu }">
    <div
      id="notification-dropDown"
      class="notification-menu__button"
      @click="showNotificationMenu = !showNotificationMenu"
    >
      <span class="notification-menu__btn">
        <svg-icon name="bell-alt" class="mr-1 text-primary" size="md" />
        <b-badge v-if="shouldShowUnreadCount" class="unread-count" pill variant="danger">{{
          formattedUnreadCount
        }}</b-badge>
      </span>
    </div>

    <div class="notification-menu__menu-box">
      <div class="notification-menu__menu-box-title">
        <div class="title-text">
          <svg-icon name="bell-alt" class="mr-1" size="sm" />
          Notifications
        </div>
        <div class="title-action" @click="markAllAsRead" :disabled="unreadCount < 1">
          <svg-icon name="check-circle" class="mr-1" size="sm" />
          Mark all as read
        </div>
      </div>
      <div style="overflow-y: hidden;">
        <div v-if="$apollo.queries.userNotifications.loading">
          <div class="text-center pt-5 mt-5"><spinner /></div>
        </div>
        <div v-else-if="!$apollo.queries.userNotifications.loading && hasNotifications">
          <ul class="notification-menu__menu" v-for="(notification, i) in userNotifications.notifications" :key="i">
            <li
              :data-name="notification.title"
              class="notification-menu__item"
              :class="{ 'notification-menu__unread': !notification.isRead }"
              @click="openNotification(notification)"
            >
              <div class="unread d-flex justify-content-between">
                <router-link to="/workspaces" class="item-link d-flex">
                  <svg-icon name="gift" size="md" class="n-icon" />
                  <div>
                    <div class="notification-title">{{ notification.title }}</div>
                    <div class="notification-msg" v-html="notification.description"></div>
                  </div>
                </router-link>
                <div class="notification-timestamp">
                  {{ notification.createdAt | moment }}
                  <div class="notification-destroy">
                    <span
                      v-b-tooltip.hover
                      :title="`Mark as ${notification.isRead ? 'Unread' : 'Read'}`"
                      @click.stop="changeStatus(notification)"
                    >
                      <svg-icon :name="notification.isRead ? 'dot-read' : 'dot-unread'" />
                    </span>
                    <span v-b-tooltip.hover title="Remove notification">
                      <svg-icon name="trash" @click.stop="remove(notification, $event)" />
                    </span>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
        <div v-else-if="!$apollo.queries.userNotifications.loading && !hasNotifications">
          <div class="text-center">
            <img class="pt-3" width="200" src="@/assets/images/empty-notification.svg" alt="" />
            <p class="mt-4">You do not have any notifications yet.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import gql from 'graphql-tag';
import { mapState } from 'vuex';
import { USER_NOTIFICATIONS_QUERY } from '~/graphql/queries';

export default {
  data() {
    return {
      showNotificationMenu: false,
      userNotifications: {},

      notifications: [
        {
          id: 1,
          title: 'Lorem Ipsom dolor',
          description: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit',
          timestamp: '2 minutes ago',
        },
        {
          id: 1,
          title: 'Lorem Ipsom dolor',
          description: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit',
          timestamp: '2 minutes ago',
        },
        {
          id: 1,
          title: 'Lorem Ipsom dolor',
          description: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit',
          timestamp: '2 minutes ago',
        },
        {
          id: 1,
          title: 'Lorem Ipsom dolor',
          description: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit',
          timestamp: '2 minutes ago',
        },
      ],
    };
  },

  created() {
    window.addEventListener('click', e => {
      if (!this.$el.contains(e.target)) {
        this.showNotificationMenu = false;
      }
    });
  },

  computed: {
    hasNotifications() {
      return (
        this.userNotifications &&
        this.userNotifications.notifications &&
        this.userNotifications.notifications.length > 0
      );
    },

    unreadCount() {
      return this.userNotifications && typeof this.userNotifications.unreadCount === 'number'
        ? this.userNotifications.unreadCount
        : 0;
    },

    formattedUnreadCount() {
      return this.unreadCount > 9 ? '9+' : this.unreadCount;
    },

    shouldShowUnreadCount() {
      return this.showUnreadCount && this.unreadCount > 0;
    },

    ...mapState('notification', {
      ringBell: state => state.ringBell,
      showUnreadCount: state => state.showUnreadCount,
    }),

    ...mapState('auth', {
      user: state => state.user,
    }),

    ...mapState('echo', {
      isRegistered: state => state.notificationRegistered,
    }),
  },

  apollo: {
    userNotifications: {
      query: USER_NOTIFICATIONS_QUERY,
      error() {
        return true;
      },
    },
  },

  filters: {
    moment: function(date) {
      return moment(date).fromNow();
    },
  },

  mounted() {
    if (!this.isRegistered) {
      const { Echo } = window;

      const user = this.user;
      if (!user) return;

      Echo.private(`users.${user.id}`).notification(notification => {
        this.$notify({
          group: 'main',
          type: 'native',
          duration: 5000,
          title: notification.title,
          text: notification.description,
        });

        this.$store.dispatch('notification/updateRingBell', true);
        setTimeout(() => this.$store.dispatch('notification/updateRingBell', false), 5000);

        this.$store.dispatch('notification/updateShowUnreadCount', true);

        let notifications = this.userNotifications.notifications;
        const index = this.getIndex(notification.id);
        if (index === -1) {
          notifications = [
            { ...notification, __typename: 'UserNotification' },
            ...this.userNotifications.notifications,
          ];
        }

        this.updateNotification({
          unreadCount: this.userNotifications.unreadCount + 1,
          notifications,
        });
      });
      this.$store.dispatch('echo/notificationRegistered');
    }
  },

  beforeDestroy() {
    if (this.isRegistered && this.user) {
      const { Echo } = window;
      const user = this.user;
      Echo.leave(`users.${user.id}`);
    }
  },

  methods: {
    updateNotification(updates) {
      const data = this.readQueryData();

      data.userNotifications = {
        ...data.userNotifications,
        ...updates,
      };

      this.writeQueryData(data);
    },

    hide() {
      this.showNotificationMenu = false;
    },

    readQueryData() {
      const store = this.$apollo.getClient();
      // Read the data from our cache for this query.
      return store.readQuery({
        query: USER_NOTIFICATIONS_QUERY,
      });
    },

    writeQueryData(data) {
      const store = this.$apollo.getClient();
      store.writeQuery({
        query: USER_NOTIFICATIONS_QUERY,
        data,
      });
    },

    hideUnreadCount() {
      // Trick to hide changelog dropdown when we open notification dropdown...
      this.$refs.notificationTitle.click();

      this.$store.dispatch('notification/updateShowUnreadCount', false);
    },

    getIndex(id) {
      return this.userNotifications.notifications.findIndex(notif => notif.id === id);
    },

    openNotification(notification) {
      if (notification.entity === 'post') {
        this.$router.push({ name: 'post.view', params: { id: notification.entityId } });

        // Hide drop down
        this.$refs.NotificationDropdown.hide();
        // Mark as read
        !notification.isRead && this.changeStatus(notification);
      }
    },

    updateUnreadCount(notification = null) {
      if (!notification) {
        return this.updateNotification({
          unreadCount: 0,
        });
      }

      if (notification.isRead) {
        this.updateNotification({
          unreadCount: this.userNotifications.unreadCount + 1,
        });
      } else {
        this.updateNotification({
          unreadCount: this.userNotifications.unreadCount - 1,
        });
      }
    },

    changeStatus(notification) {
      const index = this.getIndex(notification.id);
      if (index !== -1) {
        this.updateNotification({
          notifications: [
            ...this.userNotifications.notifications.slice(0, index),
            {
              ...this.userNotifications.notifications[index],
              isRead: !notification.isRead,
            },
            ...this.userNotifications.notifications.slice(index + 1),
          ],
        });

        this.updateUnreadCount(notification);

        this.$apollo.mutate({
          mutation: gql`
            mutation($id: String!) {
              markNotificationAs${notification.isRead ? 'Unread' : 'Read'}(id: $id)
            }
          `,
          variables: {
            id: notification.id,
          },
        });
      }
    },

    markAllAsRead() {
      this.updateNotification({
        notifications: this.userNotifications.notifications.map(notification => ({
          ...notification,
          isRead: true,
        })),
      });

      this.updateUnreadCount();

      this.$apollo.mutate({
        mutation: gql`
          mutation {
            markAllNotificationsAsRead
          }
        `,
      });
    },

    remove(notification, e) {
      const index = this.getIndex(notification.id);
      if (index !== -1) {
        e.stopPropagation();

        this.updateNotification({
          notifications: [
            ...this.userNotifications.notifications.slice(0, index),
            ...this.userNotifications.notifications.slice(index + 1),
          ],
        });

        this.updateUnreadCount(notification);

        this.$apollo.mutate({
          mutation: gql`
            mutation($id: String!) {
              removeNotification(id: $id)
            }
          `,
          variables: {
            id: notification.id,
          },
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/scss/variables';

.notification-menu {
  z-index: 9999;
  display: inline-block;
  position: relative;

  &__button {
    background: transparent;
    display: inline-block;
    text-align: center;
    cursor: pointer;
    position: relative;
    &.notify {
      animation: ring 1.5s ease;
    }
    .unread-count {
      position: absolute;
      top: 5px;
      left: 15px;
      border-radius: 50px;
      max-width: 22px;
      min-width: 9px;
      overflow: hidden;
    }
  }
  &__menu-box {
    position: absolute;
    // left: -300px;
    max-height: 90vh;
    right: -280px;
    width: 100%;
    min-width: 500px;
    max-width: 500px;
    background-color: #b7e4c7;
    border-radius: 10px;
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.2);
    transition: all 0.3s;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;
    visibility: hidden;
    opacity: 0;
    margin-top: 5px;
    padding: 10px 20px;
    &:before {
      content: '';
      background-color: transparent;
      position: absolute;
      border-bottom: 12px solid #b7e4c7;
      border-right: 12px solid transparent;
      border-left: 12px solid transparent;
      border-top: 12px solid transparent;
      top: -24px;
      right: 57%;
    }
    &::after {
      content: '';
      background-color: transparent;
    }
    &-title {
      display: flex;
      justify-content: space-between;
      color: $primary;
      text-transform: capitalize;
      text-align: center;
      .title-text {
        font-size: 18px;
      }
      .title-action {
        font-size: 14px;
      }
    }
  }
  &__menu {
    margin: 0;
    padding: 0;
    list-style: none;
  }
  &__item {
    text-align: left;
    padding: 8px 10px;
    color: $primary;
    position: relative;
    border: 1px solid transparent;

    .n-icon {
      margin-top: 4px;
      margin-right: 8px;
      font-weight: 300;
    }
    .notification-title {
      font-weight: bold;
      font-size: 18px;
    }
    .notification-msg {
      width: 250px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-top: 0px;
      font-size: 16px;
      font-weight: 300;
    }
    .notification-timestamp {
      text-align: right;
      font-size: 0.8rem;
    }
    .notification-destroy {
      display: none;
      margin-top: 0px;

      span {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 5px;
        border-radius: 8px;
        width: 30px;
        height: 30px;
        background-color: rgba(121, 251, 216, 0.046);
        aspect-ratio: 1/1;
      }
    }
    &:hover {
      color: #ffffff;
      background-color: #2d6a4f;
      border-radius: 10px;
      padding-right: 20px;
      .notification-destroy {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 5px;
      }
    }
    .item-link {
      color: inherit;
      &:hover,
      &:focus {
        color: #ffffff;
        outline: none;
        text-decoration: none;
      }
    }
  }
  &__unread {
    color: #2d6a4f;
    border-radius: 10px;
    margin: 5px 0;
    padding-right: 10px;
    background-color: rgba($gray-100, 0.7);
  }
}

.notification-menu--active .notification-menu__menu-box {
  visibility: visible;
  opacity: 1;
  margin-top: 15px;
}
@-webkit-keyframes ring {
  0% {
    -webkit-transform: rotate(35deg);
  }
  12.5% {
    -webkit-transform: rotate(-30deg);
  }
  25% {
    -webkit-transform: rotate(25deg);
  }
  37.5% {
    -webkit-transform: rotate(-20deg);
  }
  50% {
    -webkit-transform: rotate(15deg);
  }
  62.5% {
    -webkit-transform: rotate(-10deg);
  }
  75% {
    -webkit-transform: rotate(5deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
  }
}

@keyframes ring {
  0% {
    -webkit-transform: rotate(35deg);
    transform: rotate(35deg);
  }
  12.5% {
    -webkit-transform: rotate(-30deg);
    transform: rotate(-30deg);
  }
  25% {
    -webkit-transform: rotate(25deg);
    transform: rotate(25deg);
  }
  37.5% {
    -webkit-transform: rotate(-20deg);
    transform: rotate(-20deg);
  }
  50% {
    -webkit-transform: rotate(15deg);
    transform: rotate(15deg);
  }
  62.5% {
    -webkit-transform: rotate(-10deg);
    transform: rotate(-10deg);
  }
  75% {
    -webkit-transform: rotate(5deg);
    transform: rotate(5deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
}
</style>
