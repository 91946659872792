<template>
  <div class="layout">
    <b-navbar toggleable="lg" fixed="top" type="dark" variant="primary" class="Navbar shadow-sm">
      <b-container fluid>
        <b-row class="navbar-row">
          <b-navbar-brand class="cb-logo mr-5" :to="{ name: 'workspaces' }">
            <img src="@/assets/images/logo/logo-block-light-n.png" width="130" alt="Socialagency360" />
          </b-navbar-brand>

          <b-navbar-nav class="ml-auto">
            <b-nav-item :to="{ name: 'workspaces' }" active-class="active" class="mt-1">
              <svg-icon name="arrow-left" class="mt-0 mr-1" />
              Workspaces
            </b-nav-item>

            <b-nav-item-dropdown v-if="user" class="user-profile-dropdown" right>
              <template slot="button-content">
                <img
                  v-if="user"
                  :src="user.photoUrl_50"
                  width="28"
                  height="28"
                  class="rounded-circle mr-1"
                  alt="user photo"
                />
                <span class="user-profile-name">{{ user.firstName }} {{ user.lastName }}</span>
              </template>
              <b-container class="p-0">
                <div class="d-flex align-items-center border-bottom justify-content-between px-4 py-3">
                  <div class="d-flex align-items-center">
                    <img
                      v-if="user"
                      :src="user.photoUrl_50"
                      width="50"
                      height="50"
                      class="rounded-circle mr-2"
                      alt="user photo"
                    />
                    <span class="user-profile-name">{{ user.firstName }} {{ user.lastName }}</span>
                  </div>
                  <b-button
                    class="rounded-pill px-4"
                    variant="outline-primary"
                    :disabled="authBusy"
                    @click.prevent="logout"
                  >
                    <b-spinner v-if="authBusy" class="mr-1" small />
                    Logout
                  </b-button>
                </div>
                <b-row class="p-3">
                  <b-col md="12">
                    <!-- <b-dropdown-item :to="{ name: 'workspaces.create' }" active-class="active" exact>
                      Create a Workspace
                    </b-dropdown-item> -->
                    <!-- <b-dropdown-item :to="{ name: 'upgrade' }" active-class="active" exact>
                      Upgrade Your Plan
                    </b-dropdown-item> -->
                    <!-- <b-dropdown-divider></b-dropdown-divider> -->
                    <b-dropdown-item
                      href="https://docs.google.com/document/d/12ZKHXPbxZRR5114YyhifShavNMjYXFtd440pZ9Wgdpc/edit?usp=sharing"
                      target="_blank"
                    >
                      Bonuses & Resources
                    </b-dropdown-item>
                    <b-dropdown-item href="https://support.socialagency360.com/" target="_blank">
                      Help Center
                    </b-dropdown-item>
                  </b-col>
                </b-row>
              </b-container>
            </b-nav-item-dropdown>
          </b-navbar-nav>
        </b-row>
      </b-container>
    </b-navbar>
    <slot> <router-view /> </slot>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'ResellerLayout',

  computed: {
    ...mapState('auth', {
      user: state => state.user,
      authBusy: state => state.busy,
    }),
  },

  methods: {
    async logout() {
      // Log out the user.
      await this.$store.dispatch('auth/logout');

      // Redirect to login.
      this.$router.push({ name: 'signin' });
    },
  },
};
</script>

<style lang="scss">
@import '~@/scss/variables';

.Navbar {
  height: $navbar-height;

  .cb-logo {
    img {
      max-height: 35px;
    }
  }

  .navbar-row {
    width: 102.7%;
  }

  .nav-link {
    color: #fff !important;
  }

  @import '~@/scss/components/profile-dropdown';
}
</style>
